<template>
    <div id="animod" :class="[priceModeCSS]">
        <MainHeaderMobile :showNavigation="showMobileNavigation" />

        <main id="main">
            <nuxt />
        </main>

        <div v-if="showNewsletter" class="container">
            <Newsletter id="newsletter-box" class="mt-5" ref="newsletter" />
        </div>

        <PriceModeToggle :is-checkout-route="isCheckoutRoute" />
        <!-- To avoid platforms show  mobile menu for footer items only -->
        <template v-if="showMobileSeparateFooter">
            <Footer v-if="!isCheckoutRoute" />
            <CheckoutFooter v-else />
        </template>

        <FloatingBottom />
    </div>
</template>

<script>
import MainHeaderMobile from '@/components/header/MainHeaderMobile';
import authorizedLayout from '@/mixins/authorizedLayout';
import priceModeMixin from '@/mixins/priceModesGlobal';
import translatableLayout from '@/mixins/translatableLayout';
import themeableLayout from '@/mixins/themeableLayout';
import PriceModeToggle from '@/components/PriceModeToggle';
import FloatingBottom from '@/components/layout/FloatingBottom';
import Newsletter from '@/components/newsletter/Newsletter';
import Footer from '@/components/Footer';
import CheckoutFooter from '@/components/CheckoutFooter';

export default {
    name: 'MobileLayout',
    mixins: [priceModeMixin, authorizedLayout, translatableLayout, themeableLayout],
    components: {
        CheckoutFooter,
        Newsletter,
        FloatingBottom,
        MainHeaderMobile,
        PriceModeToggle,
        Footer
    }
};
</script>
